<template>
	<div class="evaluate">
		<div class="my-title clearfix">
			<span class="">评价订单</span>
		</div>
		<div class="evaluate-con">
			<div class="title">
				<span>订单号：205291684081</span>
				<em>订单时间：2021-06-18 21:41:18</em>
			</div>
			<div class="evaluate-form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="商品评分" prop="score">
						<el-rate :max="3" v-model="ruleForm.score" show-text :texts="texts"></el-rate>
					</el-form-item>

					<el-form-item label="评价详情" prop="desc">
						<el-input :rows="4" type="textarea" v-model="ruleForm.desc" placeholder="请详细评价商品~"></el-input>
					</el-form-item>

					<el-form-item label="上传图片" prop="">
						<el-upload action="#" list-type="picture-card" :auto-upload="false">
							<i slot="default" class="el-icon-plus"></i>
							<div slot="file" slot-scope="{file}">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="handleRemove(file)">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
					</el-form-item>

					<el-form-item label="匿名评价" prop="anonymous">
						<el-switch v-model="ruleForm.anonymous" active-color="#F90"></el-switch>
					</el-form-item>

					<el-form-item>
						<el-button class="btn-orange" type="warning" @click="submitForm('ruleForm')">发表</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			var validateScore = (rule, value, callback) => {
				if (!this.ruleForm.score) {
					callback(new Error('请给商品评分'));
				}
				callback();
			};
			return {
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				ruleForm: {
					score: null,
					desc: '',
					anonymous: true
				},
				rules: {
					score: [{
						required: true,
						validator: validateScore
					}],
					desc: [{
						required: true,
						message: '请填写评价详情',
						trigger: 'blur'
					}]
				},
				texts: ['差评', '中评', '好评']
			};
		},
		methods: {
			handleRemove(file) {
				console.log(file);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$message({
							message: '发表成功！',
							type: 'success'
						});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss">
	@import "../../assets/css/evaluate.scss"
</style>
